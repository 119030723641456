import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import { AuthContext } from '../context/authContext';

class ProtectedRoute extends React.Component {

    render() {
        const auth = this.context;
        const host = window.location.host.toLowerCase()
        const pathname = window.location.pathname.toLowerCase()
        const transferSwitch = pathname.includes('transferswitch')

        if(auth.user || !transferSwitch) {
            return (
                <Routes>
                <Route  path={this.props.path} element={this.props.children}/>
            </Routes>);     
        }
        //If trying to hit backup.entergy.com/transferSwitch, redirect to https://transferswitch.keystringlabs.io/transferSwitch
        else if(host === 'backup.entergy.com'){
            const url = "https://transferswitch.keystringlabs.io{}".replace("{}", window.location.pathname);
            window.location.replace(url)
        }
        else {
            const url = "/login?redirect={}".replace("{}", window.location.pathname);
            return (<Navigate to={url}></Navigate>);
        }
    }
}

ProtectedRoute.contextType = AuthContext;
export default ProtectedRoute;
