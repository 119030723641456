import React, { useEffect, useRef, useState } from 'react';

import { StyledButtonSentenceCase } from '../../components/StyledButton';

import './SignUp.scss';
import { CircularProgress, Snackbar, Alert, Link, FormControl, FormHelperText, Grid } from '@mui/material';
import PersonalInfo from '../../components/transferSwitch/PersonalInfo/PersonalInfo';
import SignUpInfo from '../../components/transferSwitch/SignUpInfo/SignUpInfo';
import { createTransferSwitch } from '../../api/TransferSwitchAPI';
import { useNavigate } from 'react-router';
import { allowedAccounts } from '../../constants/transferSwitch/allowedAccountsWithLuling';
import { AccessTime, InfoOutlined } from '@mui/icons-material'
import { StyledTooltip } from '../../components/StyledTooltip';

export default function SignUp() {

    const [selection, setSelection] = useState();
    
    const [errors, setErrors ] = useState({});
    const [personalQuestions, setPersonalQuestions] = useState({});
    //const [signUpQuestions, setSignUpQuestions] = useState({"portableGeneratorManufacturer":"", "portableGeneratorModelNumber":"", "portableGeneratorOtherInfo": "", "electricPanelSize": "", "accountOwnerName": "", "accountOwnerRelationship": "", "doorOrWindow": ""});
    const [signUpQuestions, setSignUpQuestions] = useState({});

    const [submitting, setSubmitting] = useState(false);
    const [data, setData] = useState({});
    const [openError, setOpenError] = useState(false);

    const requiredValues = ["selection", "firstName", "address", "lastName", "city", "phoneNumber", "state", "email", "zipCode", "accountNumber", "entergyEmployee", "compatibleGenerator", "circuitBreakers", "tenFeetClearance", "ownPortableGenerator", "safetyPositionGenerator", "ownerOfResidence"];
    const ref = useRef(null);
    const questionsRef = useRef(null);
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
    const phoneRegex = /^\(?\d{3}[.)-]?\s*\d{3}[-.\s]?\d{4}$/;

    const navigate = useNavigate();

    useEffect(() => {
        let newData = {...personalQuestions, ...signUpQuestions, 'selection': selection}
        if(Object.keys(errors).length > 0 ){
            formValidate({...personalQuestions, ...signUpQuestions, 'selection': selection}, getFieldsToValidate(newData))
        }
        setData(newData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [personalQuestions, signUpQuestions, selection]);    

    const getCardErrorMessage = () => {
        return "You must select an option."
    }

    const getFieldsToValidate = (data) => {
        let fieldsToValidate = requiredValues

        if(data['isAccountOwner'] === 'no'){
            fieldsToValidate = [...fieldsToValidate, 'accountOwnerName', 'accountOwnerRelationship']
        }
        if(data['panelLocation'] === 'inside'){
            fieldsToValidate = [...fieldsToValidate, 'doorOrWindow']
        }

        return fieldsToValidate
    }

    const formValidate = (inData, reqValues) => {
        
        let errs = {};

        reqValues.forEach((item) => {
            errs[item] = (!inData[item]) ? true : false;
        });

        errs["phoneInvalid"] = phoneRegex.test(inData["phoneNumber"]) ? false: true;
        errs["emailInvalid"] = emailRegex.test(inData["email"]) ? false : true;
        let accountNumTrimed = parseInt(inData.accountNumber);
        errs["accountInvalid"] = allowedAccounts.find((item) => (item === accountNumTrimed.toString())) ? false : true;
        errs["generatorInvalid"] = inData["compatibleGenerator"] === 'no'
        errs["notEntergyEmployee"] = inData["entergyEmployee"] === 'no'
        setErrors({ ...errs });

        return Object.values(errs).every((item) => (item === false));
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        let fieldsToValidate = getFieldsToValidate(data)

        if(formValidate(data, fieldsToValidate)) {
            onSubmit(e);
        }
        else {
            ref.current.scrollIntoView();
        }
    }

    const onSubmit = (e) => {
        setSubmitting(true);
        e.preventDefault();

        createTransferSwitch(data).then(() => {
            setSubmitting(false);
            navigate("/transferSwitch/confirm");
        }).catch(() => {
            setSubmitting(false);
            setOpenError(true);
        });
    }

    return (
        <>
            <grid-container class="extra-padding-2">
                <grid-row-heading class="align-header" >
                    <grid-row-full>
                        <h1 className="bold" >Sign up for Pilot</h1>
                        If your home or business meets the eligibility requirements, Entergy will conduct an installation of one (1) Entergy transfer switch with surge protection device. Additionally, we will provide comprehensive training on how to use the device. The Meter Mounted Adapter and installation services have an approximate value of $1,400 and will be offered for a monthly fee, which includes tax and installation costs.                        
                    </grid-row-full>
                </grid-row-heading>
            </grid-container>

            <grid-container class="extra-padding-2">
                <grid-row-heading class="center-text">
                    <grid-row-full>
                        <h2 >Get a transfer switch for as low as 
                            
                            <StyledTooltip arrow title={
                                <React.Fragment>
                                    The cost displayed is an approximate figure. The actual costs for the device, installation, and services amount to $17.43, while the operational and maintenance expenses total $2.86.
                                </React.Fragment>
                            }>
                                <Link class='tool-tip-button'>
                                <span class="pink-header">{` $17 per month`}</span>
                                <sup>
                                    <InfoOutlined class="info-icon"/>
                                </sup>
                                </Link>
                            </StyledTooltip> 
                        </h2>
                        Included: device + installation cost, maintenance, replacement 
                    </grid-row-full>
                </grid-row-heading>
            </grid-container>

        <form id="frmDetails" onSubmit={onFormSubmit} ref={ref}>
                
                    <Grid container class='cards-container'>
                        <FormControl required error={errors["selection"]}>
                            <Grid container item xs={12}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <sign-up-card>
                                    <div>
                                        <card-heading>
                                            $17.42 / month
                                        </card-heading>
                                        <card-subheading>
                                            <AccessTime class='clock-icon'/> 7 Year
                                        </card-subheading>
                                        <card-details>
                                            $17.42/mo for 84 months (no post-recovery payments)
                                        </card-details>
                                    </div>
                                    <button-wrapper>
                                        <StyledButtonSentenceCase 
                                            variant="contained" 
                                            disabled={selection ==='7 year'} 
                                            onClick={() => {setSelection('7 year'); setTimeout(() => {questionsRef.current.scrollIntoView()}, 10);}}
                                        >
                                            {selection ==='7 year' ? "Selected" : "Select this option"}
                                        </StyledButtonSentenceCase>
                                    </button-wrapper>
                                    </sign-up-card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                <sign-up-card>
                                    <div>
                                        <card-heading>
                                            $24.43 / month
                                        </card-heading>
                                        <card-subheading>
                                            <AccessTime class='clock-icon'/> 4 Year
                                        </card-subheading>
                                        <card-details>
                                            $24.43/mo for 48 months and $2.86/mo for 36 months after the first 48 months
                                        </card-details>
                                    </div>
                                    <button-wrapper>
                                        <StyledButtonSentenceCase 
                                            variant="contained" 
                                            disabled={selection ==='4 year'} 
                                            onClick={() => {setSelection('4 year'); setTimeout(() => {questionsRef.current.scrollIntoView()}, 10);}}
                                            >
                                                {selection ==='4 year' ? "Selected" : "Select this option"}
                                        </StyledButtonSentenceCase> 
                                    </button-wrapper>
                                    </sign-up-card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                <sign-up-card>

                                    <div>
                                        <card-heading>
                                            $75.04 / month
                                        </card-heading>
                                        <card-subheading>
                                            <AccessTime class='clock-icon'/> 1 Year
                                        </card-subheading>
                                        <card-details>
                                            $75.04/mo for 12 months and $2.86/mo for 72 months after the first 12 months
                                        </card-details>
                                    </div>
                                    <button-wrapper>
                                    
                                        <StyledButtonSentenceCase 
                                            variant="contained" 
                                            disabled={selection ==='1 year'} 
                                            onClick={() => {setSelection('1 year'); setTimeout(() => {questionsRef.current.scrollIntoView()}, 10);}}
                                        >
                                            {selection ==='1 year' ? "Selected" : "Select this option"}
                                        </StyledButtonSentenceCase>   
                                        <div ref={questionsRef}></div>
                                    </button-wrapper>
                                    </sign-up-card>
                                </Grid>
                            </Grid>
                            {errors["selection"] &&
                                <FormHelperText>{getCardErrorMessage()}</FormHelperText>
                            }
                        </FormControl>                       
                    </Grid>
            <grid-container class="form-container" >
                <SignUpInfo data={signUpQuestions} onDataChange={setSignUpQuestions} errors={errors}/>
                <PersonalInfo data={personalQuestions} onDataChange={setPersonalQuestions} errors={errors}/>
            </grid-container>
            <grid-container >
                <grid-row>
                    <center>
                        { submitting ? 
                            <CircularProgress size={'2.6rem'} /> 
                                :
                            <StyledButtonSentenceCase variant="contained" type="submit" disabled>Submit</StyledButtonSentenceCase>
                        }
                    </center>
                </grid-row>
            </grid-container>
        </form>

        <Snackbar open={openError} autoHideDuration={6000} onClose={() => (setOpenError(false))}>
            <Alert onClose={() => (setOpenError(false))} severity="error">
                There was an error saving your information. Please try again later. 
            </Alert>
        </Snackbar>
    </>
    );

}