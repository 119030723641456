import React from 'react';
import { homeCarousel } from '../../../constants/transferSwitch/homeCarousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './HomeCarousel.scss';

import { Carousel } from '../Carousel/Carousel';

export function HomeCarousel() {
    return (
        <Carousel>
            { homeCarousel.map((item, i) => <Item key={i} item={item} />)}
        </Carousel>
    )
}

function Item(props) {
    const { item } = props;

    return (
        <div>
            <carousel-data>
                <carousel-image>
                    <img src={item.image} alt={item.imageAlt} className="headingImg" />
                </carousel-image>
                <carousel-window>
                    <carousel-title>{item.name}</carousel-title>
                    <div>
                        {item.description}
                    </div>
                </carousel-window>
            </carousel-data>
        </div>
    )
} 

