import './Navbar.scss';
import logo from '../../assets/EntergyLogo_MAIN_Color.png'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

export default function Navbar(props) {
    const { openContactDialog } = props;

    let pathname = window.location.pathname.toLowerCase()
    let transferSwitch = pathname.includes('transferswitch') || pathname.includes('authcallback')

    const [activeLink, setActiveLink] = useState(pathname);

    useEffect(() => {
      setActiveLink(pathname)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);    

    return (
      <>
        <nav>
          <navbar-content>
          {!transferSwitch ?
            <>
            <navbar-logo>
              <Link to="/" onClick={() => setActiveLink("/")}><img src={logo} className="mainLogo" alt="Entergy Logo" /></Link>
            </navbar-logo>
            <navbar-link-group>
              <navbar-link>
                <HashLink to="#faq" >FAQs</HashLink>
              </navbar-link>
              <navbar-link>
                <HashLink to="#top" onClick={openContactDialog}>Contact</HashLink>
              </navbar-link>
              <navbar-link>
                <Link to="/smb" onClick={() => setActiveLink("/smb")} className={activeLink === "/smb" ? "selected-tab" : ""}>Business Customers</Link>
              </navbar-link>
            </navbar-link-group>
            </>
              :
            <>
            <navbar-logo>
              <Link to="/transferSwitch" onClick={() => setActiveLink("/transferswitch")}><img src={logo} className="mainLogo" alt="Entergy Logo" /></Link>
            </navbar-logo>
            <navbar-link-group>
              <navbar-link>
                <Link to="/transferSwitch" onClick={() => setActiveLink("/transferswitch")} className={activeLink === "/transferswitch" ? "selected-tab" : ""}>Home</Link>
              </navbar-link>
              <navbar-link>
                <HashLink to="#faq">FAQs</HashLink>
              </navbar-link>
              <navbar-link >
                <Link to="/transferSwitch/safety" onClick={() => setActiveLink("/transferswitch/safety")} className={activeLink === "/transferswitch/safety" ? "selected-tab" : ""}>Safety</Link>
              </navbar-link>
              {/* <navbar-link >
                <Link to="/transferSwitch/signUp" onClick={() => setActiveLink("/transferswitch/signUp")} className={activeLink === "/transferswitch/signup" ? "selected-tab" : ""}>Sign up</Link>
              </navbar-link> */}
            </navbar-link-group>
            </>
          }
          </navbar-content>
          <navbar-divider />
        </nav>
      </>
    )
  }