import React from 'react';
import './Home.scss';
import FAQ from '../../components/transferSwitch/FAQ/FAQ';
import { HomeCarousel } from '../../components/transferSwitch/HomeCarousel/HomeCarousel';
import { HomeBenefits } from '../../components/transferSwitch/HomeBenefits/HomeBenefits';
import { faqEntries } from '../../constants/transferSwitch/faqEntries';
import { HomeVideo } from '../../components/transferSwitch/HomeVideo/HomeVideo';
import SignUp from './SignUp';
import Safety from './Safety';
import Confirm from './Confirm'
import ProtectedRoute from '../../components/ProtectedRoute';
import Banner from '../../components/Banner/Banner';

export default function Home() {    

    return (
        <>
            <ProtectedRoute path="/transferSwitch" >
                <content-area>
                    <Banner program='transferSwitch' status='closed'/>
                    <HomeCarousel />
                    <HomeVideo />
                    <HomeBenefits />
                    <FAQ data={faqEntries} />
                </content-area> 
            </ProtectedRoute>

            <ProtectedRoute path="/transferSwitch/safety" >
                <content-area>
                    <Banner program='transferSwitch' status='closed'/>
                    <Safety />
                    <FAQ data={faqEntries} />
                </content-area>
            </ProtectedRoute>

            <ProtectedRoute path="/transferSwitch/signUp" >
                <content-area>
                    <Banner program='transferSwitch' status='closed'/>
                    <SignUp />
                    <FAQ data={faqEntries} />
                </content-area>
            </ProtectedRoute>

            <ProtectedRoute path="/transferSwitch/confirm" >
                <content-area>
                    <Confirm />
                    <FAQ data={faqEntries} />
                </content-area>
            </ProtectedRoute>
        </>
    );

}