import Layout from './components/Layout/Layout';
import './App.scss';
import Home from './pages/resiliency/Home';
import TransferSwitchHome from './pages/transferSwitch/Home';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import TagManager from 'react-gtm-module';
import { AuthContext } from './context/authContext';
import OauthSend from './components/Oauth/OauthSend';
import OauthReceive from './components/Oauth/OauthReceive';
import { AuthService } from './components/Oauth/AuthService';
import { Routes, Route } from "react-router-dom";


if (process.env.REACT_APP_GOOGLE_GTAG_KEY !== '') 
  TagManager.initialize({gtmId : process.env.REACT_APP_GOOGLE_GTAG_KEY});

function ProvideAuth({ children }) {
  const auth = AuthService();
    return (
      <AuthContext.Provider value={auth}>
        {children}
      </AuthContext.Provider>
    );
  }
  
  

function App() {

  return (
    <>
      <BrowserRouter>
        <Layout >
          <Home />
          <ProvideAuth>
            <TransferSwitchHome />

            <Routes>
              <Route path="/authcallback" element={
                <OauthReceive />
              }/>
              <Route path="/login" element={
                <OauthSend />
              }/>
            </Routes>
          </ProvideAuth>

        </Layout>

        <ScrollToTop />
      </BrowserRouter>
    </>
  );
}

export default App;
