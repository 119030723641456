import { Checkbox, MenuItem, TextField, FormControl, RadioGroup, FormHelperText } from '@mui/material';
import { useState } from 'react';
import { StyledRadio } from '../../StyledRadio';
import InputMask from "react-input-mask";
import NavDialog from '../NavDialog/NavDialog';

export default function SMBInfo(props) {
    const { data, onDataChange, errors } = props;
    const [openTermsDialog, setOpenTermsDialog] = useState(false);
    const states = ["AR", "LA", "MS", "TX"];
    
    const handleSelect = (name, value) => {
        onDataChange({
            ...data,
            [name]: value
        });
    };

    const handleAddlTermsClick = (e) => {
        setOpenTermsDialog(true); 
        e.preventDefault();
    }

    const handleChange = (e) => {
        handleSelect(e.target.name, e.target.value);
    };

    const handleCheckChange = (e) => {
        handleSelect(e.target.name, e.target.checked);
    };

    const getErrorMessage = (...fieldNames) => {
        return fieldNames.map((item) => (getSingleError(item))).join(" ");
    }

    const getSingleError = (fieldName) => {
        if(fieldName === "emailInvalid")
            return errors[fieldName] ? "Please enter a valid e-mail address" : " ";
        else if(fieldName === "phoneInvalid")
            return errors[fieldName] ? "Please enter a valid phone number" : " ";
        else if(fieldName === "zipError")
            return errors[fieldName] ? "This program is currently only available to Entergy New Orleans homeowners" : " ";       
        else
            return errors[fieldName] ? "This field is required. " : " ";
    }

    return (
        <>
            <grid-container>
                <grid-row>
                    <grid-item-full>
                        <h3>Verify Your Service Area</h3>
                    </grid-item-full>
                </grid-row>
                <grid-row>
                    <grid-item-half>
                    <InputMask 
                            mask="99999"
                            maskPlaceholder=""
                            value={data.serviceZip ?? ""} 
                            onChange={handleChange}>
                            <TextField 
                                id="serviceZip" 
                                name="serviceZip"
                                inputMode="zip"
                                error={errors["serviceZip"] || errors["zipError"]}
                                helperText={getErrorMessage("serviceZip", "zipError") }
                                label="Zip Code" fullWidth />
                        </InputMask>
                    </grid-item-half>
                    <grid-item-half>&nbsp;</grid-item-half>
                </grid-row>
                <grid-row>
                    <grid-item-full>
                    <div>Do you rent or own?</div>
                        <FormControl required error={errors["rentOrOwn"]}>
                            <RadioGroup name="rentOrOwn" value={data.rentOrOwn ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="own" />Own</div>
                                <div><StyledRadio value="rent" />Rent</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("rentOrOwn")} </FormHelperText>
                        </FormControl>
                    </grid-item-full>
                </grid-row>
                <grid-row>
                    <grid-item-full>
                        <div>Are you authorized to make changes to the property?</div>
                        <FormControl required error={errors["authorized"]}>
                            <RadioGroup name="authorized" value={data.authorized ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("authorized")} </FormHelperText>
                        </FormControl>
                    </grid-item-full>
                </grid-row>                
                <grid-row>
                    <grid-item-full>
                        <h3>Business Details</h3>
                    </grid-item-full>
                </grid-row>
                <grid-row>
                    <grid-item-half>
                        <TextField 
                            id="businessName" 
                            name="businessName"
                            value={data.businessName ?? ""}
                            onChange={handleChange}
                            error={errors["businessName"]}
                            helperText={getErrorMessage("businessName")}
                            label="Business Name" fullWidth />
                    </grid-item-half>
                    <grid-item-half>
                        &nbsp;
                    </grid-item-half>
                </grid-row>
                <grid-row>
                    <grid-item-half>
                        <TextField 
                            id="address" 
                            name="address"
                            value={data.address ?? ""}
                            onChange={handleChange}
                            error={errors["address"]}
                            helperText={getErrorMessage("address")}
                            label="Installation Address" fullWidth />
                    </grid-item-half>
                    <grid-item-half>
                        <TextField 
                            id="aptNumber"
                            name="aptNumber"
                            value={data.aptNumber ?? ""}
                            onChange={handleChange} 
                            error={errors["aptNumber"]}
                            helperText={getErrorMessage("aptNumber")}
                            label="Address Line #2" fullWidth />
                    </grid-item-half>
                </grid-row>
                <grid-row>
                    <grid-item-half>
                        <TextField 
                            id="city" 
                            name="city"
                            value={data.city ?? ""}
                            onChange={handleChange}
                            error={errors["city"]}
                            helperText={getErrorMessage("city")}
                            label="City" fullWidth />
                    </grid-item-half>
                    <grid-item-half>
                        <TextField
                            id="state"
                            name="state"
                            value={data.state ?? ""}
                            label="State"
                            select
                            fullWidth
                            onChange={handleChange}
                            error={errors["state"]}
                            helperText={getErrorMessage("state")} >
                            {
                                states.map((option) => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))
                            }
                        </TextField>
                    </grid-item-half>
                </grid-row>
                <grid-row>
                    <grid-item-half>
                        <InputMask mask="99999" value={data.zipCode ?? ""} onChange={handleChange} maskPlaceholder="">
                            <TextField 
                                id="zipCode"
                                name="zipCode"
                                error={errors["zipCode"]}
                                helperText={getErrorMessage("zipCode")}
                                label="Zip" fullWidth />
                        </InputMask>
                    </grid-item-half>
                    <grid-item-half>&nbsp;
                    </grid-item-half>
                </grid-row>
                <grid-row>
                    <grid-item-full>
                        <h3>Contact Details</h3>
                    </grid-item-full>
                </grid-row>
                <grid-row>
                    <grid-item-half>
                        <TextField 
                            id="firstName" 
                            name="firstName"
                            value={data.firstName ?? ""}
                            onChange={handleChange}
                            label="First Name" 
                            error={errors["firstName"]}
                            fullWidth 
                            helperText={getErrorMessage("firstName")}/>
                    </grid-item-half>
                    <grid-item-half>
                        <TextField 
                            id="lastName" 
                            name="lastName"
                            value={data.lastName ?? ""}
                            onChange={handleChange}
                            error={errors["lastName"]}
                            helperText={getErrorMessage("lastName")}
                            label="Last Name" fullWidth />
                    </grid-item-half>
                </grid-row>
                <grid-row>
                    <grid-item-half>
                        <TextField 
                            id="jobTitle" 
                            name="jobTitle"
                            value={data.jobTitle ?? ""}
                            onChange={handleChange}
                            label="Job Title" 
                            error={errors["jobTitle"]}
                            fullWidth 
                            helperText={getErrorMessage("jobTitle")}/>
                    </grid-item-half>
                    <grid-item-half style={(errors["email"] || errors["emailInvalid"]) ? {} : {marginBottom: '1rem'}}>
                        <TextField 
                            id="email"
                            name="email"
                            inputMode="email"
                            value={data.email ?? ""}
                            error={errors["email"] || errors["emailInvalid"]}
                            onChange={handleChange}
                            helperText={getErrorMessage("email", "emailInvalid")}
                            label="E-mail Address" fullWidth />
                    </grid-item-half>
                </grid-row>                
                <grid-row>
                    <grid-item-half style={(errors["phoneNumber"] || errors["phoneInvalid"]) ? {} : {marginBottom: '1rem'}}>
                        <InputMask 
                            mask="(999) 999-9999" 
                            maskPlaceholder='(XXX) XXX-XXXX'
                            value={data.phoneNumber ?? ""} 
                            onChange={handleChange}>
                            <TextField 
                                id="phoneNumber" 
                                name="phoneNumber"
                                inputMode="tel"
                                error={errors["phoneNumber"] || errors["phoneInvalid"]}
                                helperText={getErrorMessage("phoneNumber", "phoneInvalid")}
                                label="Phone Number" fullWidth />
                        </InputMask>
                    </grid-item-half>
                    <grid-item-half>&nbsp;</grid-item-half>
                </grid-row>
                <grid-row>
                    <no-wrap>
                        <grid-item-sm>
                        <Checkbox 
                                id="agreeToAddlTerms"
                                name="agreeToAddlTerms"
                                checked={data.agreeToAddlTerms ?? false}
                                
                                onChange={handleCheckChange} />
                        </grid-item-sm>
                        <grid-item class={ errors["agreeToAddlTerms"] ? "error-text" : "" }>I agree to <a href="#top" onClick={handleAddlTermsClick}>additional terms.</a></grid-item>
                    </no-wrap>
                </grid-row>
                <grid-row>
                    <no-wrap>
                        <grid-item-sm>
                        <Checkbox 
                                id="agreeToTerms"
                                name="agreeToTerms"
                                checked={data.agreeToTerms ?? false}
                                onChange={handleCheckChange} />
                        </grid-item-sm>
                        <grid-item class={ errors["agreeToTerms"] ? "error-text" : "" }>I agree to allow Entergy New Orleans to share my information with an authorized Generac installer and others.</grid-item>
                    </no-wrap>
                </grid-row>
                <grid-row>
                    <no-wrap>
                        <grid-item-sm>

                            <Checkbox 
                                id="agreeToSub"
                                name="agreeToSub"
                                checked={data.agreeToSub ?? false}
                                
                                onChange={handleCheckChange} />
                        </grid-item-sm>
                        <grid-item class={ errors["agreeToSub"] ? "error-text" : "" }>I understand that an authorized Generac installer may recommend a different unit and quote a different installation price after completing the building assessment.</grid-item>
                    </no-wrap>
            </grid-row>
            </grid-container>
            <NavDialog open={openTermsDialog} onClose={() => (setOpenTermsDialog(false))} title="Terms of Service" width='md'>
                Entergy New Orleans, LLC (“Entergy”) provides this Generac Discount Standby Generator Program Pilot as a courtesy to its customers to make it easier and more efficient for them to evaluate whether to install a standby generator. The Pilot Program will make popular generators available to the first approximately 100 individuals who purchase a generator.   By submitting the information requested on this page, you are directing Entergy and its affiliates to share such information with Generac Power Systems, Inc. and its affiliates, and generator dealers and installers (collectively “Vendors”) who may contact you to schedule an on-site assessment at your convenience for the purpose of providing a quote, discussing Generac products and related services, and providing such products and services if you decide to purchase them. There is no obligation to purchase a generator if you apply for an assessment under this Pilot Program. You are free to compare prices with other providers, and pursue alternatives to the products and services contemplated herein. The estimate provided at the Entergy website is based on an algorithm, and the final price may vary depending on a number of factors, and considerations that will be identified by a site visit.  The decision to provide such information to the site, or purchase generator products and services is yours alone, and neither Entergy nor its affiliates or parent company shall be responsible or liable for such products or services, or for the use made by of the information that is provided to the Vendors. You agree to hold Entergy, its affiliates and parent company harmless for any and all damages that may arise in connection with this generator discount program, and any related financing.
            </NavDialog>
        </>
    )
    
  }