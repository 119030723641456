import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './Layout.scss';
import { useState } from 'react';
import NavDialog from '../resiliency/NavDialog/NavDialog';

export default function Layout({ children }) {
    const [openContactDialog, setOpenContactDialog] = useState(false);

    return (
      <>
        <header-area>
          <Navbar 
            openContactDialog={() => (setOpenContactDialog(true))} 
            
          />
        </header-area>
        <main-area>
          {children}
        </main-area>
        <footer-color>
          <footer-area>
            <Footer/>
          </footer-area>
        </footer-color>
        <NavDialog open={openContactDialog} onClose={() => (setOpenContactDialog(false))} title="Contact Us">
          If you have any questions that aren't answered here, please email us at <a href="mailto:communitybackup@entergy.com">communitybackup@entergy.com</a> and we'll respond in 24 - 48 hours.
        </NavDialog>
      </>
    )
  }